<template>
 <div :class="toggleMenu ? 'open p-2' : 'p-2'">
   <img
       @click="toggle"
       class="cursor-pointer"
       src="@/assets/images/icons/hamburger-ico.svg"
       alt="Icona menu documenti"
       width="25"
   />
   <div v-if="toggleMenu" class="menu mt-4">
     <p @click="closePractice">
       Chiudi pratica
     </p>
     <p @click="archivePractice">
       {{ practiceDeleted ? 'Riapri Pratica' : 'Archivia Pratica'}}
     </p>
   </div>
 </div>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { sweetAlertCallback, sweetAlertSuccess } from "@/utilities/sweetalert";
import {closePracticeById, deletePractice} from "@/api/practices.api";

export default {
  name: "DocumentMenuActions",
  props: ['practiceDeleted'],
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    let toggleMenu = ref(false);

    async function closePractice() {
      const practiceId = +route.params.id;

      const response = await closePracticeById(practiceId);

      if (response.status) {
        await sweetAlertSuccess('success', 'Pratica chiusa con successo.');
      } else {
        await sweetAlertSuccess('error', 'Errore durante la chiusura della pratica.');
      }
    }

    function archivePractice() {
      toggle();
      const practiceId = +route.params.id;
      const title = props.practiceDeleted ? 'Sei sicuro di voler riaprire la pratica?' : 'Sei sicuro di voler archiviare la pratica?';

      sweetAlertCallback(title)
        .then(async (result) => {
          if (!result.isConfirmed) return;

          const response = await deletePractice(0, practiceId);

          if (!!response) return sweetAlertSuccess('success', 'Pratica riaperta con successo.');

          await sweetAlertSuccess('success', 'Pratica archiviata con successo.');

          await router.push({ name: 'Practices' });
        })
    }

    function toggle() {
      toggleMenu.value = !toggleMenu.value;
    }

    return {
      toggleMenu,
      toggle,
      archivePractice,
      closePractice
    }
  }
}
</script>

<style scoped>
  .open {
    background-color: #fff;
    border: 1px solid #bfbfbf;
    width: 100%;
    min-height: 200px;
    position: absolute;
    top: 12px;
    z-index: 99;
  }

  .menu p {
    margin: 0.5rem 0;
    cursor: pointer;
    font-weight: 500;
  }

  .menu p:hover {
    color: #173759bd;
  }
</style>