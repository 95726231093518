<template>
  <div class="mb-4" :class="center && 'text-center'">
    <h4 class="c-blue text-uppercase fw-bolder mb-0">
      {{ title }}
    </h4>
    <small>
      <i>{{ description }}</i>
    </small>
  </div>
</template>

<script>
export default {
  name: "DocumentHeading",
  props: {
    title: String,
    description: String,
    center: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

}
</script>

<style scoped>

</style>