
import { defineComponent, PropType } from 'vue';
import Modal from "@/components/modals/Modal.vue";

export default defineComponent({
  name: "DocumentCard",
  components: { Modal },
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    extension: String,
    createdAt: String as PropType<string>
  },
})
