
import {defineComponent, onMounted, PropType, ref} from 'vue';
import { uploadDocument } from "@/api/documents.api";
import AlertError from "@/components/alerts/AlertError.vue";

export default defineComponent({
  name: 'CreateDocumentModal',
  components: {AlertError},
  emits: ['closeModal', 'documentUploaded'],
  props: {
    show: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: false,
    },
    categoryId: {
      type: Number as PropType<number>,
      required: true,
    },
    practiceId: {
      type: Number as PropType<number>,
      required: true,
    }
  },
  setup(props, { emit }) {
    let file = ref<any>('');
    let error = ref<string>('');
    let label = ref<string>('');

    async function onUploadDocument(e: any) {
      file.value = e.target.files[0];
    }

    function reset() {
      file.value = '';
      error.value = '';
      label.value = '';
    }

    async function saveDocument() {
      if (!file.value) return;

      if (!label.value) return error.value = 'Il campo titolo non può essere vuoto';

      let formData = new FormData();

      formData.append("file", file.value);
      formData.append('label', JSON.stringify(label.value));

      const response: any = await uploadDocument(props.categoryId, props.practiceId, formData);

      // TODO: Change this horrible logic
      if (!response.data) {
        if (response.response?.data) {
          if (response.response.data.errors?.length > 1)
            error.value = response.response.data.errors.file[1];
          else
            error.value = response.response.data.errors.file[0];
        }
        return;
      }

      emit('documentUploaded', response.data);
      reset();
    }

    onMounted(() => {
      reset();
    });

    function closeModal() {
      return emit('closeModal', true);
    }

    return {
      file,
      error,
      label,
      closeModal,
      onUploadDocument,
      saveDocument
    }
  }
})
