
import {
  defineComponent,
  ref,
  reactive,
  onMounted, computed,
} from 'vue';
import { paginateDocuments, showPractice } from "@/api/practices.api";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";
import { Document } from "@/types/Document";
import DocumentHeading from "@/components/statics/DocumentHeading.vue";
import DocumentCard from "@/components/cards/DocumentCard.vue";
import Modal from "@/components/modals/Modal.vue";
import DocumentIconCard from "@/components/cards/DocumentIconCard.vue";
import IconBack from "@/components/actions-bar/actions/IconBack.vue";
import DocumentDownload from "@/components/actions-bar/actions/DocumentDownload.vue";
import DocumentBigButton from "@/components/actions-bar/actions/DocumentBigButton.vue";
import { DocumentAction } from "@/types/ActionEnum";
import CustomModal from "@/components/modals/CreateDocumentModal.vue";
import { sweetAlertCallback, sweetAlertSuccess} from "@/utilities/sweetalert";
import { forceDeleteDocument } from "@/api/documents.api";
import DocumentMenuActions from "@/components/actions-bar/actions/DocumentMenuActions.vue";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";

export default defineComponent({
  name: "Documents",
  components: {
    AlertSuccess,
    DocumentMenuActions,
    CustomModal,
    DocumentBigButton,
    DocumentDownload,
    IconBack,
    DocumentIconCard,
    Modal,
    DocumentCard,
    DocumentHeading
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const practiceId = ref<number>(+route.params.id);
    const documents = ref<Document[]>([]);
    const selected = reactive({
      file: '' as string,
      extension: '' as string,
    });
    let createMode = ref<boolean>(false);
    let practice = ref<any>({});
    let message = ref<string>('');

    function deleteDocument(documentId: number) {
      const index = documents.value.findIndex((document: Document) => document.id === documentId);

      sweetAlertCallback('Sei sicuro di voler eliminare il documento?')
        .then(async (result) => {
          if (result.isConfirmed) {

            if (index <= -1) return sweetAlertSuccess('error', 'Si è verificato un errore: documento non trovato.');

            const response: any = await forceDeleteDocument(documentId);

            if (response.status) {
              documents.value.splice(index, 1);

              return sweetAlertSuccess('success', 'Documento eliminato.');
            }

            return sweetAlertSuccess('error', 'Si è verificato un errore.');
          }
          return;
        })
    }

    function documentUploaded(document: Document): void {
      documents.value.push(document);
      closeCreation();

      sweetAlertSuccess('success', 'Documento caricato');
    }

    async function getPractice() {
      const response = await showPractice(+route.params?.id);
      practice.value = response.data;
    }

    function onClickButtonHandler(action: DocumentAction) {
      switch (action) {
        case DocumentAction.Upload:
          return createMode.value = true;
        default:
          console.log('Non definito.');
          return;
      }
    }

    function closeCreation() {
      createMode.value = false;
    }

    async function getDocuments() {
      const response = await paginateDocuments(practiceId.value);
      documents.value = response.data;

      if (!documents.value) return router.push({ name: 'Practices' });

      if (documents.value.length === 0) return message.value = 'Non sono presenti documenti per questa pratica.';
    }

    function openPreview(file: string, extension: any): void {
      selected.file = file;
      selected.extension = extension;
    }

    function isPdf(extension: string): boolean {
      return extension.toLowerCase() === 'pdf' || extension.includes('pdf');
    }

    onMounted(() => {
      getDocuments();
      getPractice();
    });

    return {
      admin: computed(() => store.getters.user.is_admin),
      userId: computed(() => store.getters.user.id),
      documents,
      selected,
      createMode,
      practice,
      message,
      practiceId,
      openPreview,
      isPdf,
      onClickButtonHandler,
      closeCreation,
      documentUploaded,
      deleteDocument
    }
  }
})
