<template>
  <div
    class="document text-center d-flex justify-content-center align-items-center flex-column my-3 mx-2"
  >
    <img
      :src="require(`@/assets/images/icons/${getIconName()}`)"
      width="44"
      alt="Icona del file"
      class="cursor-pointer mt-3"
    />
    <p class="title cursor-pointer mb-0">
      {{ title }} <br />
      {{ new Date(created).toLocaleDateString() }}
    </p>
    <div class="bar bg-blue d-flex justify-content-around align-items-center my-2 p-1">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DocumentIconCard',
  props: {
    title: String,
    created: String,
    extension: String,
  },
  setup(props) {

    function getIconName() {
      const extension = props.extension;

      switch (extension?.toLowerCase()) {
        case 'pdf':
          return 'pdf-ico.svg';
        case 'doc':
        case 'docx':
          return 'docx-ico.svg';
        case 'png':
          return 'png-ico.svg';
        case 'jpg':
          return 'jpg-ico.svg';
        case 'jpeg':
          return 'jpeg-ico.svg';
        default:
          return 'file-ico.svg';
      }
    }

    return {
      getIconName,
    }
  }
})
</script>

<style scoped>
  .document {
    width: 100px;
    min-height: 90px;
    margin: 0.3rem 0;
  }

  .document .title {
    font-size: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: inherit;
  }

  .document:hover {
    border-radius: 1rem;
    background-color: #38608a;
    color: #fff;
  }

  .document .bar {
    width: 96%;
    height: 30px;
    border-radius: 0.6rem;
  }
</style>