<template>
  <a :href="`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${file}`" :download="'file.' + extension">
    <div v-if="text">
      {{ text }}
    </div>
    <div v-if="icon">
      <img
        :src="require(`@/assets/images/icons/${icon}`)"
        alt="icon"
        width="18"
      />
    </div>
  </a>
</template>

<script>
export default {
  name: 'DocumentDownload',
  props: {
    icon: String,
    extension: {
      type: String,
      required: true,
    },
    file: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false,
    }
  }
}
</script>

<style scoped>

</style>