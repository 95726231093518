<template>
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col-12">
        <h2 v-if="practiceId" class="mb-4 fw-bolder c-blue text-uppercase">
          Pratica #{{ practiceId }}
        </h2>
      </div>
      <div class="col-12">
        <AlertSuccess
          :message="message"
        />
      </div>
      <div class="col-md-9 d-flex justify-content-between">
        <DocumentBigButton
          text="Carica documento"
          icon="add-white-ico.svg"
          :action="1"
          @onClickButton="onClickButtonHandler"
        />
        <DocumentBigButton
            text="Scarica documenti"
            icon="download-ico.svg"
            :action="0"
            @onClickButton="onClickButtonHandler"
        />
      </div>
      <div class="col-md-1 d-flex align-items-center justify-content-start justify-content-md-end my-4 my-md-0">
        <IconBack />
      </div>
      <div class="col-md-2 d-flex align-items-center position-relative">
        <DocumentMenuActions
          :practice-deleted="documents[0]?.practice_deleted"
        />
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row" style="min-height: 500px">
      <div class="col-12 col-md-6 order-md-0 order-1 divider bg-column py-4 px-2">
        <DocumentHeading
          :center="true"
          title="I miei documenti"
          description="Qui troverai i tuoi documenti, utili per l'elaborazione della pratica."
        />
        <div class="d-flex flex-wrap justify-content-center">
          <div v-for="document in documents" :key="document.id">
            <div
              v-if="!document.admin_id"
              @click="openPreview(document.file, document.extension)"
            >
              <DocumentIconCard
                :extension="document.extension"
                :title="document.label"
                :created="document.created_at"
              >
                <DocumentDownload
                    :file="selected.file"
                    :extension="selected.extension"
                    :icon="'download-ico.svg'"
                />
                <i
                  v-if="userId === document.user_id || admin"
                  class="bi bi-trash text-white cursor-pointer"
                  @click="deleteDocument(document.id)"
                ></i>
                <img
                    src="@/assets/images/icons/open-doc-ico.svg"
                    alt="Icona apri documento"
                    width="18"
                    class="cursor-pointer"
                    @click="openPreview(document.file, document.extension)"
                    data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                />
              </DocumentIconCard>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 order-md-1 order-0 pe-0 ps-3 bg-column p-4">
        <DocumentHeading
          :center="true"
          title="Documenti Ufficiali"
          description="Qui troverai i documenti caricati dall'amministrazione."
        />
        <div class="d-flex justify-content-center flex-wrap">
          <div v-for="document in documents" :key="document.id">
            <div
              v-if="document.admin_id"
              @click="openPreview(document.file, document.extension)"
            >
              <DocumentIconCard
                :extension="document.extension"
                :title="document.label"
                :created="document.created_at"
              >
                <DocumentDownload
                    :file="selected.file"
                    :extension="selected.extension"
                    :icon="'download-ico.svg'"
                />
                <i
                  v-if="admin"
                  class="bi bi-trash text-white cursor-pointer"
                  @click="deleteDocument(document.id)"
                ></i>
                <img
                    src="@/assets/images/icons/open-doc-ico.svg"
                    alt="Icona apri documento"
                    width="18"
                    class="cursor-pointer"
                    data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                    @click="openPreview(document.file, document.extension)"
                />
              </DocumentIconCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal title="Anteprima documento">
    <div v-if="isPdf(selected.extension)">
      <DocumentDownload
          :file="selected.file"
          :extension="selected.extension"
          text="Scarica il documento"
      />
      <iframe width="100%" :src="'data:application/pdf;base64,' + selected.file + '#zoom=55%'" />
    </div>
    <div v-else>
      <small>
        Siamo spiacenti ma al momento non è possibile visualizzare l'anteprima di documenti in formato {{ selected.extension }}. <br/>
        <DocumentDownload
          :file="selected.file"
          :extension="selected.extension"
          text="Clicca qui"
        />
        per scaricare il documento.
      </small>
    </div>
  </Modal>

  <div v-if="practice.id && practice.category_id">
    <CustomModal
        :category-id="practice.category_id"
        :practice-id="practice.id"
        :show="createMode"
        @closeModal="closeCreation"
        @documentUploaded="documentUploaded"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  onMounted, computed,
} from 'vue';
import { paginateDocuments, showPractice } from "@/api/practices.api";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";
import { Document } from "@/types/Document";
import DocumentHeading from "@/components/statics/DocumentHeading.vue";
import DocumentCard from "@/components/cards/DocumentCard.vue";
import Modal from "@/components/modals/Modal.vue";
import DocumentIconCard from "@/components/cards/DocumentIconCard.vue";
import IconBack from "@/components/actions-bar/actions/IconBack.vue";
import DocumentDownload from "@/components/actions-bar/actions/DocumentDownload.vue";
import DocumentBigButton from "@/components/actions-bar/actions/DocumentBigButton.vue";
import { DocumentAction } from "@/types/ActionEnum";
import CustomModal from "@/components/modals/CreateDocumentModal.vue";
import { sweetAlertCallback, sweetAlertSuccess} from "@/utilities/sweetalert";
import { forceDeleteDocument } from "@/api/documents.api";
import DocumentMenuActions from "@/components/actions-bar/actions/DocumentMenuActions.vue";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";

export default defineComponent({
  name: "Documents",
  components: {
    AlertSuccess,
    DocumentMenuActions,
    CustomModal,
    DocumentBigButton,
    DocumentDownload,
    IconBack,
    DocumentIconCard,
    Modal,
    DocumentCard,
    DocumentHeading
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const practiceId = ref<number>(+route.params.id);
    const documents = ref<Document[]>([]);
    const selected = reactive({
      file: '' as string,
      extension: '' as string,
    });
    let createMode = ref<boolean>(false);
    let practice = ref<any>({});
    let message = ref<string>('');

    function deleteDocument(documentId: number) {
      const index = documents.value.findIndex((document: Document) => document.id === documentId);

      sweetAlertCallback('Sei sicuro di voler eliminare il documento?')
        .then(async (result) => {
          if (result.isConfirmed) {

            if (index <= -1) return sweetAlertSuccess('error', 'Si è verificato un errore: documento non trovato.');

            const response: any = await forceDeleteDocument(documentId);

            if (response.status) {
              documents.value.splice(index, 1);

              return sweetAlertSuccess('success', 'Documento eliminato.');
            }

            return sweetAlertSuccess('error', 'Si è verificato un errore.');
          }
          return;
        })
    }

    function documentUploaded(document: Document): void {
      documents.value.push(document);
      closeCreation();

      sweetAlertSuccess('success', 'Documento caricato');
    }

    async function getPractice() {
      const response = await showPractice(+route.params?.id);
      practice.value = response.data;
    }

    function onClickButtonHandler(action: DocumentAction) {
      switch (action) {
        case DocumentAction.Upload:
          return createMode.value = true;
        default:
          console.log('Non definito.');
          return;
      }
    }

    function closeCreation() {
      createMode.value = false;
    }

    async function getDocuments() {
      const response = await paginateDocuments(practiceId.value);
      documents.value = response.data;

      if (!documents.value) return router.push({ name: 'Practices' });

      if (documents.value.length === 0) return message.value = 'Non sono presenti documenti per questa pratica.';
    }

    function openPreview(file: string, extension: any): void {
      selected.file = file;
      selected.extension = extension;
    }

    function isPdf(extension: string): boolean {
      return extension.toLowerCase() === 'pdf' || extension.includes('pdf');
    }

    onMounted(() => {
      getDocuments();
      getPractice();
    });

    return {
      admin: computed(() => store.getters.user.is_admin),
      userId: computed(() => store.getters.user.id),
      documents,
      selected,
      createMode,
      practice,
      message,
      practiceId,
      openPreview,
      isPdf,
      onClickButtonHandler,
      closeCreation,
      documentUploaded,
      deleteDocument
    }
  }
})
</script>

<style scoped>
  .divider {
    border-top: 5px solid #173759;
  }

  button {
    font-size: 0.6rem;
    width: 40%;
    border-radius: 14px;
  }

  iframe {
    height: 80vh;
  }

  .bg-column {
    background-color: #f4f4f4;
  }

  .bg-column:first-child {
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
  }

  .bg-column:last-child {
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  button:hover {
    background-color: #1a3b5f !important;
    color: #f4f4f4!important;
  }


  @media screen and (min-width: 845px) {
    .bg-column:first-child {
      border-top-left-radius: 22px;
      border-bottom-left-radius: 22px;

      border-bottom-right-radius: 0;
    }

    .bg-column:last-child {
      border-top-right-radius: 22px;
      border-bottom-right-radius: 22px;

      border-top-left-radius: 0;
    }

    .divider {
      border-right: 5px solid #173759;
      border-top: unset;
    }

    button {
      font-size: 0.9rem;
      width: 48%;
    }
  }
</style>