
import { defineComponent, PropType } from 'vue';
import { DocumentAction } from "@/types/ActionEnum";
import Modal from "@/components/modals/Modal.vue";

export default defineComponent({
  name: 'DocumentBigButton',
  components: {Modal},
  emits: ['onClickButton'],
  props: {
    icon: {
      type: String as PropType<string>,
      required: true,
    },
    width: {
      type: String as PropType<string>,
      default: '40'
    },
    alt: {
      type: String as PropType<string>,
      default: 'Icona pulsante'
    },
    text: {
      type: String as PropType<string>,
      required: true
    },
    action: {
      type: Number as PropType<number>,
      required: true
    }
  },
  setup(props, { emit })  {

    function onClickButton() {
      switch (props.action) {
        case DocumentAction.Download:
          return emit('onClickButton', DocumentAction.Download);
        case DocumentAction.Upload:
          return emit('onClickButton', DocumentAction.Upload);
        default:
          return -1;
      }
    }

    return {
      onClickButton
    }

  }
})
