
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DocumentIconCard',
  props: {
    title: String,
    created: String,
    extension: String,
  },
  setup(props) {

    function getIconName() {
      const extension = props.extension;

      switch (extension?.toLowerCase()) {
        case 'pdf':
          return 'pdf-ico.svg';
        case 'doc':
        case 'docx':
          return 'docx-ico.svg';
        case 'png':
          return 'png-ico.svg';
        case 'jpg':
          return 'jpg-ico.svg';
        case 'jpeg':
          return 'jpeg-ico.svg';
        default:
          return 'file-ico.svg';
      }
    }

    return {
      getIconName,
    }
  }
})
