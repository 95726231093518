<template>
  <div
    class="back bg-blue text-white cursor-pointer"
    @click="back"
  >
    <i
      class="bi bi-arrow-left d-flex justify-content-center align-items-center w-100 h-100"
    ></i>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "IconBack",
  setup() {
    const router = useRouter();

    function back() {
      return router.go(-1);
    }

    return {
      back
    }
  }
}
</script>

<style scoped>
  .back {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
</style>