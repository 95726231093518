<template>
  <div class="document-card mb-4 p-2 text-center bg-white">
    <h6>
      Nome: {{ title }}
    </h6>
    <p class="mb-3">
      Creato il: {{ new Date(createdAt).toLocaleDateString() }}
    </p>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Modal from "@/components/modals/Modal.vue";

export default defineComponent({
  name: "DocumentCard",
  components: { Modal },
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    extension: String,
    createdAt: String as PropType<string>
  },
})
</script>

<style scoped>
  .document-card {
    width: 180px;
    border: 3px solid #173759;
    border-radius: 20px;
  }
  .document-card h6 {
    font-size: 0.95rem;
  }
  .document-card p {
    font-size: 0.9rem;
  }

  iframe {
    height: 70vh;
  }
</style>