import httpClient from "@/api/httpClient";

const END_POINT = '/documents';
const PRACTICE_END_POINT = '/practices/';
const CATEGORY_END_POINT = '/categories/';
const DOCUMENT_END_POINT = '/documents/';

const uploadDocument = (categoryId: number, practiceId: number, file: any) => {
    return httpClient.post(
        CATEGORY_END_POINT + categoryId + PRACTICE_END_POINT + practiceId + END_POINT,
        file,
        {
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
            },
        }
    );
}

const forceDeleteDocument = (documentId: number) => httpClient.delete(
    DOCUMENT_END_POINT + documentId
)

export {
    uploadDocument,
    forceDeleteDocument
}