<template>
  <div v-if="show">
    <div class="overlay"></div>
    <div id="custom-modal" class="shadow">
      <div class="header p-3">
        <div class="close cursor-pointer" @click="closeModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>
        <h4 class="c-blue fw-bolder mb-0">
          Carica un documento
        </h4>
      </div>
      <div class="body p-3">
        <AlertError :error="error" />
        <small>
          Carica il tuo documento in piattaforma. Sono consentiti i seguenti formati: pdf, docx, jpg, png e jpeg
        </small>
        <form class="mt-4" enctype="multipart/form-data">
          <div class="mb-3">
            <label for="title" class="form-label c-blue fw-bold ms-3 mb-0">
              Titolo
            </label>
            <input
              id="title"
              class="form-control"
              type="text"
              v-model="label"
            />
          </div>
          <div class="mb-3">
            <label for="formFile" class="form-label c-blue fw-bold ms-3 mb-0">
              Documento
            </label>
            <input
              id="formFile"
              class="form-control"
              type="file"
              ref="file"
              v-on:change="onUploadDocument"
            />
          </div>

          <button type="button" class="btn bg-blue mt-4" @click="saveDocument">
            Upload
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, ref} from 'vue';
import { uploadDocument } from "@/api/documents.api";
import AlertError from "@/components/alerts/AlertError.vue";

export default defineComponent({
  name: 'CreateDocumentModal',
  components: {AlertError},
  emits: ['closeModal', 'documentUploaded'],
  props: {
    show: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: false,
    },
    categoryId: {
      type: Number as PropType<number>,
      required: true,
    },
    practiceId: {
      type: Number as PropType<number>,
      required: true,
    }
  },
  setup(props, { emit }) {
    let file = ref<any>('');
    let error = ref<string>('');
    let label = ref<string>('');

    async function onUploadDocument(e: any) {
      file.value = e.target.files[0];
    }

    function reset() {
      file.value = '';
      error.value = '';
      label.value = '';
    }

    async function saveDocument() {
      if (!file.value) return;

      if (!label.value) return error.value = 'Il campo titolo non può essere vuoto';

      let formData = new FormData();

      formData.append("file", file.value);
      formData.append('label', JSON.stringify(label.value));

      const response: any = await uploadDocument(props.categoryId, props.practiceId, formData);

      // TODO: Change this horrible logic
      if (!response.data) {
        if (response.response?.data) {
          if (response.response.data.errors?.length > 1)
            error.value = response.response.data.errors.file[1];
          else
            error.value = response.response.data.errors.file[0];
        }
        return;
      }

      emit('documentUploaded', response.data);
      reset();
    }

    onMounted(() => {
      reset();
    });

    function closeModal() {
      return emit('closeModal', true);
    }

    return {
      file,
      error,
      label,
      closeModal,
      onUploadDocument,
      saveDocument
    }
  }
})
</script>

<style scoped>
  #custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 55vh;
    background-color: #f4f4f4;
    z-index: 999;
    border-radius: 6px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #37393c78;
  }

  .close {
    position: absolute;
    left: 92%;
  }

  .header {
    border-bottom: 1px solid #dee2e6;
  }

  button,
  .form-control {
    border-radius: 22px;
  }
</style>